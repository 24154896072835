import { useState } from '#app';

export type ProductType =
    'cake'
    | 'patisserie'
    | 'pastry'
    | 'pie-tart'
    | 'bread'
    | 'dessert'
    | 'savoury'
    | 'cupcake';

/**
 * Product types that are available.
 */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const useProductTypes = () => useState<ProductType[]>('productTypes', () => []);

export default useProductTypes;
